import article from "./page/article";
import colorScheme from "./plugins/colorScheme";
import containerWidgetTabs from "./plugins/containerWidgetTabs";
import facebookPixelProxy from "@exp/scripts/plugins/facebook-pixel-proxy";
import factbox from "./plugins/factbox";
import gtm from "@exp/scripts/integration/gtm";
import htmlWidget from "./plugins/htmlWidget";
import iframeSync from "./plugins/iframeSync";
import listPage from "./page/listPage";
import loadNonBlockingStylesheets from "./plugins/loadNonBlockingStylesheets";
import poll from "./plugins/poll";
import rotator from "./plugins/rotator";
import searchPage from "./page/searchPage";
import section from "./page/section";
import siteMenu from "@exp/components/site-menu/site-menu.mjs";
import stickyHeader from "./plugins/stickyHeader";
import toggleConsentNotice from "./plugins/toggleConsentNotice";
import topMenu from "@exp/scripts/plugins/top-menu";
import trackPageUnfreeze from "./helpers/trackPageUnfreeze";
import videoPlayer from "./plugins/videoPlayer";
import webVitals from "@exp/scripts/integration/web-vitals";
import webtvArticle from "./page/webtvArticle";
gtm();
trackPageUnfreeze();
loadNonBlockingStylesheets();
stickyHeader();
siteMenu();
topMenu();
colorScheme();
const pageTemplateName = window.Exp && window.Exp.pageTemplateName;
const pageSpecificScriptRunner = getPageSpecificScriptRunner();
const componentInitiators = {
  high: [videoPlayer, iframeSync],
  medium: [htmlWidget, factbox, poll, containerWidgetTabs, rotator]
};
if (pageSpecificScriptRunner) {
  pageSpecificScriptRunner(componentInitiators);
} else {
  componentInitiators.high.forEach(fn => fn());
  componentInitiators.medium.forEach(fn => fn());
}
toggleConsentNotice();
webVitals();
facebookPixelProxy();
function getPageSpecificScriptRunner() {
  switch (pageTemplateName) {
    case "article":
    case "storytelling-article":
    case "live-page":
    case "podcast-article":
      return article;
    case "section":
      return section;
    case "webtv-article":
      return webtvArticle;
    case "search-page":
      return searchPage;
    case "todays-news":
      return listPage;
  }
}